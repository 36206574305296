<template>
   <div class="footer wf-section">
    <br>
    <div class="div-block-15">
      <a id="w-node-_513aceae-6d54-e40c-8a16-474425a4668b-f85b217c" href="https://www.sosclick.cl/" target="_self"
        class="footer-link-2" >Web SOSClick</a>
      <a id="w-node-_513aceae-6d54-e40c-8a16-474425a4668b-f85b217c" href="#planes" class="footer-link-2">Planes</a>
      <a id="w-node-_513aceae-6d54-e40c-8a16-474425a4668b-f85b217c" href="http://ventasboton.sosclick.cl/"
        class="footer-link-2" target="_self">Botón Adulto Mayor</a>
      <a id="w-node-_513aceae-6d54-e40c-8a16-474425a4668b-f85b217c" href="http://ventas.iduam.com/"
        class="footer-link-2" target="_self">Iduam</a>
      <a id="w-node-_513aceae-6d54-e40c-8a16-474425a4668b-f85b217c"
        href="https://www.youtube.com/channel/UC9_7svBs8MccyNykc8L3qRg" class="footer-link-2"
        target="_self">VideoTutoriales</a>
      <a id="w-node-_513aceae-6d54-e40c-8a16-474425a4668b-f85b217c" href="#" class="footer-link-2">Contacto</a>


      <!--a id="w-node-_513aceae-6d54-e40c-8a16-474425a4668b-f85b217c" href="#" class="footer-link-2">Home</a>
      <a id="w-node-_513aceae-6d54-e40c-8a16-474425a4668d-f85b217c" href="#" class="footer-link-2">Planes</a>
      <a id="w-node-_513aceae-6d54-e40c-8a16-474425a4668f-f85b217c" href="#" class="footer-link-2">Tutoriales</a>
      <a id="w-node-_513aceae-6d54-e40c-8a16-474425a46691-f85b217c" href="#" class="footer-link-2">Contacto</a-->
    <img src="images/logo-natural.svg" loading="lazy"  
      width="186" alt="" class="image-11">
    </div>
    <div class="div-block-13">
      <div class="div-block-14">
        <div class="linea"></div>
      </div>
      <div class="text-block-3">2022 © Naturalphone S.A. Todos los derechos reservados.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
</style>