
<template>
  <div>
    <div class="section wf-section">
      <div
        class="div-block-19"
        style="
          top: 0%;
          left: 0%;
          right: 0%;
          bottom: 0%;
          margin-left: 10%;
          margin-right: 10%;
          margin-top: auto;
          margin-bottom: auto;
        "
      >
        <div class="div-block-20">
          <a
            @click="checkOut()"
            target="_self"
            class="nav-link2-3 w-nav-link"
            ><h1 class="heading-5">Compra Directa</h1>
            <p
              class="paragraph-3"
              style="padding-left: 10px; padding-top: 10px"
            >
              Pagar directamente con Flow
            </p></a
          >
        </div>
        <div class="div-block-21" style="margin-right: 0px"></div>
        <div class="div-block-20">
          <a
            href="https://www.click2call.cl/?ShareLink=5"
            target="_self"
            class="nav-link2-3 w-nav-link"
            style="padding-left: 20px"
            ><h1 class="heading-5">Compra Asistida</h1>
            <p
              class="paragraph-3"
              style="padding-left: 10px; padding-top: 10px"
            >
              Asistencia de un ejecutivo en línea para tu compra
            </p></a
          >
        </div>
      </div>
      <div class="div-block-18"></div>
    <div class="footer wf-section" style="bottom: 0; position: absolute; left: 0; right:0;">
      <div class="div-block-15">
        <img
          src="images/logo-natural.svg"
          loading="lazy"
          width="186"
          alt=""
          class="image-11"
        />
        <div class="text-block-3">
          2022 © Naturalphone S.A. Todos los derechos reservados.
        </div>
      </div>
      <div class="div-block-13">
        <div class="div-block-14">
          <div class="linea"></div>
        </div>
      </div>
    </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Compras",
  components: {},
  data () {
    return {
      selectedPlan: null,
    }
  },
  mounted(){
    this.selectedPlan = this.$router.currentRoute.query.plan
    if (!this.selectedPlan) {
      this.$router.push({ name: "Home" });
    }
  },
  methods: {
    checkOut(){
      console.log(this.selectedPlan)
      this.$router.push({ name: "Checkout", query: { plan: this.selectedPlan } });
    }
  }
};
</script>

<style>
@import "../../assets/css/normalize.css";
@import "../../assets/css/webflow.css";
@import "../../assets/css/sosclick-abuelos.webflow.css";

[data-wf-bgvideo-fallback-img] {
  display: none;
}

@media (prefers-reduced-motion: reduce) {
  [data-wf-bgvideo-fallback-img] {
    position: absolute;
    z-index: -100;
    display: inline-block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>