<template>
  <v-layout justify-center align-center style="background-color: #ffffff">
    <v-snackbar
        v-model="showMessage"
        vertical
        bottom
        center
        :color="messageColor"
    >
      <p class="mb-0">{{ message }}</p>
    </v-snackbar>
    <v-container>
      <v-row v-if="!loading" justify="center" align="center">
        <v-col cols="12" sm="6" v-if="showLogin && !showCheckout">
          <h3 class="text-center black--text">Ingresa a tu cuenta</h3>
          <v-layout
              flex-column
              flex-md-row
              justify-center
              align-center
              style="width: 100%"
          >
            <!-- <v-img
              max-height="300"
              max-width="300"
              :src="require('../../assets/imgs/logo_sosclick.png')"
            ></v-img> -->
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="d-flex flex-column flex-grow-1"
                style="max-width: 400px; width: 100%"
            >
              <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="Email"
                  required
                  class="custom-placeholer-color"
              ></v-text-field>
              <v-text-field
                  v-model="userPassword"
                  :rules="passwordRules"
                  label="Contraseña"
                  required
                  :append-icon="passType ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (passType = !passType)"
                  :type="passType ? 'password' : 'text'"
              ></v-text-field>
              <v-btn
                  :disabled="!valid"
                  color="success"
                  class="mr-4"
                  @click="loginUser"
              >
                Ingresar
              </v-btn>
              <v-btn
                  color="primary"
                  class="mr-4 mt-4"
                  @click="showLogin = false"
              >
                Registrate
              </v-btn>
            </v-form>
          </v-layout>
        </v-col>
        <v-col cols="12" sm="6" v-if="!showLogin && !showCheckout">
          <h3 class="text-center black--text">Registrate</h3>
          <v-layout
              flex-column
              flex-md-row
              justify-center
              align-center
              style="width: 100%"
          >
            <!-- <v-img
              max-height="300"
              max-width="300"
              :src="require('../../assets/imgs/logo_sosclick.png')"
            ></v-img> -->
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="d-flex flex-column flex-grow-1"
                style="max-width: 400px; width: 100%"
            >
              <v-text-field
                  v-model="name"
                  :rules="nameRules"
                  label="Nombre"
                  required
                  class="custom-placeholer-color"
              ></v-text-field>
              <v-text-field
                  v-model="lastName"
                  :rules="lastNameRules"
                  label="Apellido"
                  required
                  class="custom-placeholer-color"
              ></v-text-field>
              <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="Email"
                  required
                  class="custom-placeholer-color"
              ></v-text-field>
              <v-text-field
                  v-model="userPassword"
                  :rules="passwordRules"
                  label="Contraseña"
                  required
                  :append-icon="passType ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (passType = !passType)"
                  :type="passType ? 'password' : 'text'"
              ></v-text-field>
              <v-btn
                  :disabled="!valid"
                  color="success"
                  class="mr-4"
                  @click="registerUser"
              >
                Registrarse
              </v-btn>
              <v-btn
                  color="primary"
                  class="mr-4 mt-4"
                  @click="showLogin = true"
              >
                Ingresar
              </v-btn>
            </v-form>
          </v-layout>
        </v-col>
        <v-col cols="12" sm="6" v-if="showCheckout">
          <h3 class="text-center black--text">Datos de envío</h3>
          <v-layout
              flex-column
              flex-md-row
              justify-center
              align-center
              style="width: 100%"
          >
            <!-- <v-img
              max-height="300"
              max-width="300"
              :src="require('../../assets/imgs/logo_sosclick.png')"
            ></v-img> -->
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="d-flex flex-column flex-grow-1"
                style="max-width: 400px; width: 100%"
            >
              <v-text-field
                  v-model="name"
                  :rules="nameRules"
                  label="Nombre"
                  required
                  class="custom-placeholer-color"
              ></v-text-field>
              <v-text-field
                  v-model="lastName"
                  :rules="lastNameRules"
                  label="Apellido"
                  required
                  class="custom-placeholer-color"
              ></v-text-field>
              <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="Email"
                  required
                  class="custom-placeholer-color"
              ></v-text-field>
              <v-text-field
                  v-model="phone"
                  :rules="phoneRules"
                  label="Teléfono"
                  required
                  class="custom-placeholer-color"
              ></v-text-field>
              <v-text-field
                  v-model="address"
                  :rules="addressRules"
                  label="Dirección"
                  required
                  class="custom-placeholer-color"
              ></v-text-field>

              <!-- select de region -->
              <v-select
                  v-model="region"
                  :items="regions"
                  item-text="region"
                  item-value="id"
                  label="Región"
                  :rules="regionRules"
                  required
                  class="custom-placeholer-color"
                  @change="changeRegion"
              ></v-select>
              <!-- select de comuna -->
              <v-select
                  v-model="commune"
                  :items="communes"
                  item-text="comuna"
                  item-value="id"
                  label="Comuna"
                  :rules="communeRules"
                  required
                  class="custom-placeholer-color"
                  no-data-text="Debe seleccionar una región"
              ></v-select>
              <v-text-field
                  v-model="zipCode"
                  label="Código postal"
                  class="custom-placeholer-color"
              ></v-text-field>
              <v-btn
                  :disabled="!valid"
                  color="success"
                  class="mr-4"
                  @click="startPayment"
              >
                Comprar
              </v-btn>
            </v-form>
          </v-layout>
        </v-col>
        <v-col cols="12" sm="6">
          <v-layout
              flex-column
              flex-md-row
              justify-center
              align-center
              style="width: 100%"
          >
            <v-card class="mx-auto rounded-xl" style="width: 100%">
              <v-card-text class="text-center">
                <img
                    :src="selectedPlan.image"
                    loading="lazy"
                    alt=""
                    class=""
                    style="max-width: 350px"
                />
                <h2 class="">{{ selectedPlan.name }}</h2>
                <h4 class="">{{ selectedPlan.subtitle }}</h4>

                <!-- <div class="">Valor Bruto</div>
                <div class="font-weight-bold text-h5">
                  ${{ selectedPlan.price | currency }}/Año
                </div> -->
                <div class=""></div>
                <v-divider inset></v-divider>
                <v-list-item v-if="selectedPlan">
                  <v-list-item-content
                      style="flex-direction: column; width: 100%"
                  >
                    <v-list-item-title
                        v-for="(item, index) in selectedPlan.descriptionList"
                        :key="index"
                        style="
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        width: 100%;
                        white-space: pre-line;
                      "
                    >
                      <p
                          style="
                          font-size: 14px;
                          font-weight: 500;
                          color: #000000;
                        "
                      >
                        {{ item.title }}
                      </p>
                      <p
                          style="
                          font-size: 12px;
                          color: #000000;
                          align-self: center;
                        "
                      >
                        {{ item.subtitle }}
                      </p>
                    </v-list-item-title>

                    <!--li>
              <div class="pricing-feature ">Monitoreo online</div>
            </li>
            <li>
              <div class="pricing-feature ">Consulta online</div>
            </li-->
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-layout>
        </v-col>
      </v-row>
      <v-row v-if="loading">
        <v-col cols="12" class="text-center">
          <v-progress-circular
              indeterminate
              color="success"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
import {mapGetters} from "vuex";
import {comunasActivas, getUser, login, regionesActivas, register} from "../helpers/api/user";
import {startPaymentPlan,} from "../helpers/api/planes";

export default {
  name: "Checkout",
  components: {},
  computed: {
    ...mapGetters(["userData"]),
  },
  filters: {
    currency(value) {
      return value.toLocaleString("es-CL", {
        style: "currency",
        currency: "CLP",
      });
    },
  },
  watch: {
    userData: function () {
      this.loading = false;
      this.showLogin = false;
      this.showCheckout = true;
      this.email = this.$store.state.userData.email || "";
      this.name = this.$store.state.userData.name.split(" ")[0] || "";
      this.lastName = this.$store.state.userData.name.split(" ")[1] || "";
      this.phone = this.$store.state.userData.info.telefono || "";
      setTimeout(() => {
        this.validate();
      }, 100);
    },
  },
  data: () => ({
    loading: false,
    valid: false,
    showMessage: false,
    showLogin: true,
    showCheckout: false,
    toPayPlan: {
      product_id: 5,
      qty: 1,
    },
    requestedStatus: false,
    pais_id: 81,
    regions: [],
    communes: [],
    messageColor: "error",
    message: "",
    email: "",
    emailRules: [
      (v) => !!v || "Email es obligatorio",
      (v) => /.+@.+\..+/.test(v) || "Email debe ser válido",
    ],
    userPassword: "",
    passwordRules: [(v) => !!v || "Contraseña es requerida"],
    passType: true,
    name: "",
    nameRules: [(v) => !!v || "Nombre es requerido"],
    lastName: "",
    lastNameRules: [(v) => !!v || "Apellido es requerido"],
    phone: "",
    phoneRules: [(v) => !!v || "Teléfono es requerido"],
    address: "",
    addressRules: [(v) => !!v || "Dirección es requerida"],
    region: "",
    regionRules: [(v) => !!v || "Región es requerida"],
    commune: "",
    communeRules: [(v) => !!v || "Comuna es requerida"],
    zipCode: "",
    zipCodeRules: [(v) => !!v || "Código postal es requerido"],
    items: [
      {
        name: "Solución Personal",
        subtitle: "Cámara + App",
        price: "",
        id: 5,
        image: "images/Kit-1.png",
        description:
            "Al generar una alerta, tu red de seguridad podrá comunicarse contigo por videollamada. Puedes crear una red de seguridad de hasta 5 personas y visualizar la cámara desde tu App en tiempo real. Una solución orientada a personas que necesiten todas las funcionalidades de SOSCLICK Con auto-monitoreo de una cámara provista por SOSCLICK.",
        descriptionList: [
          {
            title: "App SOSCLICK",
            subtitle:
                "Acceso a la aplicación SOSCLICK que te permitirá manejar y monitorear todo desde tu smartphone.",
          },
          {
            title: "Red de 5 Usuarios",
            subtitle:
                "Al generar una alerta, tu red de seguridad podrá comunicarse contigo por videollamada.",
          },
          {
            title: "Cámara IP",
            subtitle:
                "Podrás visualizar en tiempo real la cámara desde la aplicación SOSCLICK.",
          },
        ],
      },
      {
        name: "Solución Full",
        subtitle: "Cámara + Parlantes + App",
        price: "",
        id: 6,
        image: "images/Kit-2.png",
        description:
            "Al generar una alerta, tu red de seguridad podrá comunicarse contigo por videollamada. Puedes crear una red de seguridad de hasta 5 personas y además visualizar la cámara desde tu App en tiempo real. Además, puedes tener una comunicación por voz vía la cámara, escuchando con claridad a tu interlocutor, ya que, los parlantes externos aseguran una excelente comunicación bidireccional. Plan orientado a personas que necesiten todas las funcionalidades de SOSCLICK con monitoreo de una cámara, que incluye parlantes adicionales.",
        descriptionList: [
          {
            title: "App SOSCLICK",
            subtitle:
                "Acceso a la aplicación SOSCLICK que te permitirá manejar y monitorear todo desde tu smartphone.",
          },
          {
            title: "Red de 5 Usuarios",
            subtitle:
                "Al generar una alerta, tu red de seguridad podrá comunicarse contigo por videollamada.",
          },
          {
            title: "Cámara IP + Parlantes Externos",
            subtitle:
                "Podrás visualizar en tiempo real la cámara desde la aplicación SOSCLICK.",
          },
        ],
      },
      {
        name: "Solución Premium",
        subtitle: "Cámara + Parlantes Módem WIFI 4g + App",
        price: "",
        id: 7,
        image: "images/Kit-3.png",
        description:
            "Al generar una alerta, tu red de seguridad podrá comunicarse contigo por videollamada. Puedes crear una red de seguridad de hasta 5 personas y visualizar la cámara desde tu App en tiempo real. Además puedes tener una comunicación por voz vía la cámara, escuchando con claridad a tu interlocutor, ya que, los parlantes externos aseguran una excelente comunicación bidireccional. Podrás utilizar el Modem 4g en lugares que no cuenten con Internet. Plan orientado a personas que necesiten todas las funcionalidades de SOSCLICK con monitoreo de una cámara, que incluye parlantes adicionales, más un modem 4g para lugares que no cuenten con internet.",
        descriptionList: [
          {
            title: "App SOSCLICK",
            subtitle:
                "Acceso a la aplicación SOSCLICK que te permitirá manejar y monitorear todo desde tu smartphone.",
          },
          {
            title: "Red de 5 Usuarios",
            subtitle:
                "Al generar una alerta, tu red de seguridad podrá comunicarse contigo por videollamada.",
          },
          {
            title: "Cámara IP + Parlantes Externos",
            subtitle:
                "Podrás visualizar en tiempo real la cámara desde la aplicación SOSCLICK.",
          },
          {
            title: "Módem WIFI 4g",
            subtitle:
                "Podrás utilizar el Modem 4g para lugares que no cuentan con internet.",
          },
        ],
      },
    ],
    selectedPlan: {},
  }),
  mounted() {
    // obtener id del plan desde la url
    this.selectedPlan = this.items.find(
        (item) => item.id == this.$router.currentRoute.query.plan
    );

    // si no existe el plan, redireccionar a la pagina home
    if (!this.selectedPlan) {
      this.$router.push({name: "Home"});
    }
    this.getRegions(this.pais_id);
    this.toPayPlan.product_id = this.selectedPlan.id;
    this.toPayPlan.qty = 1;

    // si el usuario ya esta logueado, mostrar el formulario de checkout
    // if (this.$store.state.isAuthenticated) {
    //   this.getUserInfo();
    // }
  },
  beforeDestroy() {
    this.$store.commit("logoutUser", null);
  },
  methods: {
    getCommunes() {
      this.communes = RegionesYComunas.regiones.find(
          (region) => region.NombreRegion == this.region
      ).comunas;
      this.communeRules = [(v) => !!v || "Comuna es requerida"];
    },
    async startPayment() {

      this.loading = true;
      this.toPayPlan = {
        ...this.toPayPlan,
        email: this.email,
        name: this.name,
        last_name: this.lastName,
        phone: this.phone,
        address: this.address,
        region_id: this.region,
        comuna_id: this.commune,
        zip_code: this.zipCode || null,
      };
      try {
        const planPayment = await startPaymentPlan(this.toPayPlan);
        if (planPayment.code === 200) {
          if (
              planPayment.flowData &&
              planPayment.flowData.url &&
              planPayment.flowData.token
          ) {
            window.location.href = planPayment.flowData.url + "?token=" + planPayment.flowData.token;
          } else {
            this.loading = false;
            this.showMessage = true;
            this.messageColor = "error";
            this.message = "Lo sentimos, no se pudo iniciar el pago";
          }
        }
      } catch (error) {
        this.loading = false;
        this.showMessage = true;
        this.messageColor = "error";
        this.message = "Lo sentimos, no se pudo iniciar el pago";
      }
    },
    changeRegion(value) {
      this.getComunas(value)
    },
    async getRegions(pais_id) {
      const regiones = await regionesActivas({pais_id})
      this.regions = regiones.regiones
      if (this.region) {
        await this.getComunas(this.region)
      } else {
        this.comunas = []
      }
    },
    async getComunas(region_id) {
      const comunas = await comunasActivas({region_id})
      this.communes = comunas.comunas
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    loginUser() {
      const self = this;
      login(this.email, this.userPassword)
          .then(function (resp) {
          })
          .catch(function (error) {
            self.message =
                "Lo sentimos, ha ocurrido un error inesperado. Por favor inténtalo nuevamente.";
            if (error.response && error.response.status == 401) {
              self.message =
                  "Email o contraseña incorrectos. Por favor verifica los datos ingresados.";
            }
            self.messageColor = "warning";
            self.showMessage = true;
            console.log(error.response);
          });
    },
    registerUser() {
      const self = this;
      register(this.email, this.userPassword)
          .then(function (resp) {
            self.message = "Usuario registrado correctamente";
            self.messageColor = "success";
            self.showMessage = true;
            self.showLogin = true;
            self.showCheckout = false;
          })
          .catch(function (error) {
            self.message =
                "Lo sentimos, ha ocurrido un error inesperado. Por favor inténtalo nuevamente.";
            // if (error.response && error.response.status == 401) {
            //   self.message =
            //     "Email o contraseña incorrectos. Por favor verifica los datos ingresados.";
            // }
            self.messageColor = "warning";
            self.showMessage = true;
            console.log(error.response);
          });
    },
    async getUserInfo() {
      if (!this.$store.state.userData) {
        this.loading = true;
        await getUser()
            .then((resp) => {
              this.loading = false;
              this.showLogin = false;
              this.showCheckout = true;
              this.email = resp.user.email || "";
              this.name = resp.user.name.split(" ")[0] || "";
              this.lastName = resp.user.name.split(" ")[1] || "";
              this.phone = resp.user.info.telefono || "";
            })
            .catch((error) => {
              this.loading = false;
              this.showLogin = true;
              this.showCheckout = false;
              console.log(error);
            });
      }
      this.validate();
    },
  },
};
</script>

<style>
</style>