
<template>
  <div>
    <div class="section wf-section">
      <div class="botones-marketplace">
        <a
          href="https://ventas.iduam.com/"
          class="buton-iduam w-inline-block"
          target="_self"
          ><img
            src="images/iduam-logo.svg"
            loading="lazy"
            alt=""
            class="image-16"
          />
          <div class="text-block-5">Iduam Marketplace</div>
        </a>
        <a
          href="https://ventasboton.sosclick.cl/"
          class="buton-sosclickboton w-inline-block"
          target="_self"
          ><img
            src="images/logo-2.png"
            loading="lazy"
            alt=""
            class="image-16"
          />
          <div class="text-block-5">SOSCLICK Botón <br />Marketplace</div>
        </a>
      </div>
      <div class="div-block-19">
        <div class="div-block-20">
          <h1 class="heading-4">SOSCLICK</h1>
          <h1 class="heading-5">Adulto Mayor</h1>
          <a href="#" class="lightbox-link-2 w-inline-block w-lightbox">
            <div class="text-block-4">Ver Video</div>
            <script type="application/json" class="w-json">
              {
                "items": [
                  {
                    "url": "https://www.youtube.com/watch?v=muP9CKiVazs",
                    "originalUrl": "https://www.youtube.com/watch?v=muP9CKiVazs",
                    "width": 940,
                    "height": 528,
                    "thumbnailUrl": "https://i.ytimg.com/vi/muP9CKiVazs/hqdefault.jpg",
                    "html": "<iframe class=\"embedly-embed\" src=\"//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FmuP9CKiVazs%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DmuP9CKiVazs&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FmuP9CKiVazs%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube\" width=\"940\" height=\"528\" scrolling=\"no\" title=\"YouTube embed\" frameborder=\"0\" allow=\"autoplay; fullscreen\" allowfullscreen=\"true\"></iframe>",
                    "type": "video"
                  }
                ],
                "group": ""
              }
            </script>
          </a>
        </div>
        <div class="div-block-21"></div>
        <div class="div-block-33">
          <div
            data-w-id="2fd0aed1-4a21-1b76-c436-a05df6a26989"
            class="div-block-22"
          >
            <h1
              style="
                -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                  rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)
                  rotateY(0) rotateZ(0) skew(0, 0);
                -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)
                  rotateY(0) rotateZ(0) skew(0, 0);
                transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)
                  rotateY(0) rotateZ(0) skew(0, 0);
                opacity: 1;
              "
              class="heading-7"
            >
              más acompañados.
            </h1>
            <h1
              style="
                -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                  rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)
                  rotateY(0) rotateZ(0) skew(0, 0);
                -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)
                  rotateY(0) rotateZ(0) skew(0, 0);
                transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)
                  rotateY(0) rotateZ(0) skew(0, 0);
                opacity: 1;
              "
              class="heading-7"
            >
              más conectados
            </h1>
            <h1
              style="
                -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                  rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)
                  rotateY(0) rotateZ(0) skew(0, 0);
                -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)
                  rotateY(0) rotateZ(0) skew(0, 0);
                transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)
                  rotateY(0) rotateZ(0) skew(0, 0);
                opacity: 1;
              "
              class="heading-7"
            >
              Más seguros
            </h1>
            <h1
              style="
                -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                  rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)
                  rotateY(0) rotateZ(0) skew(0, 0);
                -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)
                  rotateY(0) rotateZ(0) skew(0, 0);
                transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)
                  rotateY(0) rotateZ(0) skew(0, 0);
                opacity: 1;
              "
              class="heading-7"
            >
              Más Cerca
            </h1>
          </div>
          <p class="paragraph-3">
            <strong>SOSCLICK</strong> es una Plataforma de Seguridad y
            Autoayuda, muy amigable para las personas mayores que no tienen
            manejo habitual del uso de la tecnología y que no requieren de
            manipulación de un dispositivo para responder a una llamada de un
            hijo u otro familiar.
          </p>
        </div>
      </div>
      <div class="div-block-18"></div>
      <div
        data-poster-url="videos/happy-senior-friends-putting-their-hands-together-2021-10-01-09-34-49-utc-poster-00001.jpg"
        data-video-urls="videos/happy-senior-friends-putting-their-hands-together-2021-10-01-09-34-49-utc-transcode.mp4,videos/happy-senior-friends-putting-their-hands-together-2021-10-01-09-34-49-utc-transcode.webm"
        data-autoplay="true"
        data-loop="true"
        data-wf-ignore="true"
        class="background-video w-background-video w-background-video-atom"
      >
        <video
          id="8afb599c-6322-16c1-508f-2b80d0ad7da6-video"
          autoplay=""
          loop=""
          style="
            background-image: url('videos/happy-senior-friends-putting-their-hands-together-2021-10-01-09-34-49-utc-poster-00001.jpg');
          "
          muted=""
          playsinline=""
          data-wf-ignore="true"
          data-object-fit="cover"
        >
          <source
            src="videos/happy-senior-friends-putting-their-hands-together-2021-10-01-09-34-49-utc-transcode.mp4"
            data-wf-ignore="true"
          />
          <source
            src="videos/happy-senior-friends-putting-their-hands-together-2021-10-01-09-34-49-utc-transcode.webm"
            data-wf-ignore="true"
          />
        </video>
      </div>
    </div>
    <section class="hero-heading-left wf-section">
      <div class="containerhtml">
        <div class="hero-wrapper">
          <div class="hero-split">
            <div
              data-w-id="f83c004b-f8c3-d0d1-da0e-1a837e183de9"
              style="opacity: 0"
            >
              <h1 class="heading-10">
                <strong
                  data-w-id="5afd30a9-8040-d7bd-d1dc-e70399583589"
                  style="opacity: 0"
                  class="bold-text-2"
                  >SOSCLiCK</strong
                >
              </h1>
              <h1 class="heading-9">Adulto Mayor</h1>
              <p class="margin-bottom-24px" style="text-align: justify">
                Nace idealmente como una herramienta para acercar a las
                personas, en tiempos en que el confinamiento y la rutina nos
                impide el desplazamiento para estar más presente en la vida de
                nuestros seres queridos. Entrega al adulto mayor la sensación de
                compañía permanente y a sus familiares la posibilidad de estar
                más conectados con sus padres o abuelos en cualquier momento del
                día. Además de monitorear para poder atender una emergencia con
                la urgencia que esta amerita.<br />‍<br />La mayor parte de los
                adultos mayores hoy en día no posee conocimiento tecnológico o
                no tiene conexión a Internet, la
                <strong>&quot;SOSCAM&quot; </strong>crea su propia red de
                Internet usando un plan de datos de bajo precio.<br />‍<br />Además,
                al instalar una <strong>&quot;SOSCAM&quot; </strong>en casa de
                un adulto mayor, sus hijos podrán Monitorear a sus padres a
                distancia, usando nuestra Aplicación Móvil
                <strong>&quot;SOSCLICK&quot; </strong>y gracias al audio
                bidireccional de la propia Cámara, podrán comunicarse en todo
                momento con ellos. <br /><br />
              </p>
              <a href="#" class="lightbox-link-2 _2 w-inline-block w-lightbox">
                <div class="text-block-4">Ver Video</div>
                <script type="application/json" class="w-json">
                  {
                    "items": [
                      {
                        "url": "https://www.youtube.com/watch?v=zHei9mfgWeA",
                        "originalUrl": "https://www.youtube.com/watch?v=zHei9mfgWeA",
                        "width": 940,
                        "height": 528,
                        "thumbnailUrl": "https://i.ytimg.com/vi/zHei9mfgWeA/hqdefault.jpg",
                        "html": "<iframe class=\"embedly-embed\" src=\"//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FzHei9mfgWeA%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DzHei9mfgWeA&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FzHei9mfgWeA%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube\" width=\"940\" height=\"528\" scrolling=\"no\" title=\"YouTube embed\" frameborder=\"0\" allow=\"autoplay; fullscreen\" allowfullscreen=\"true\"></iframe>",
                        "type": "video"
                      }
                    ],
                    "group": ""
                  }
                </script>
              </a>
            </div>
          </div>
          <div class="hero-split">
            <img
              src="images/principal.png"
              loading="lazy"
              style="opacity: 0"
              data-w-id="37c21435-5a75-1ffb-72c2-2eeaada3d0f7"
              sizes="100vw"
              srcset="
                images/principal-p-500.png  500w,
                images/principal-p-800.png  800w,
                images/principal.png       1029w
              "
              alt=""
              class="shadow-two"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="features-list wf-section">
      <div class="containerhtml">
        <div class="features-wrapper-two">
          <div class="features-left">
            <p
              data-w-id="3413235f-c38d-84f5-877a-3beb7a5a449e"
              style="opacity: 0"
              class="features-paragraph"
            >
              Es la nueva solución para el cuidado de tus familiares, somos una
              plataforma con una serie de características únicas que te ayudaran
              a estar más cerca de ellos. <br />
            </p>
          </div>
          <ul
            data-w-id="3413235f-c38d-84f5-877a-3beb7a5a44a4"
            style="opacity: 0"
            role="list"
            class="features-right w-list-unstyled"
          >
            <li class="features-block-two">
              <img
                src="images/Grupo-3907.svg"
                loading="lazy"
                alt=""
                class="features-image"
              />
              <p style="text-align: justify">
                <strong class="bold-text-4">Fácil de usar</strong> <br />El
                dispositivo está especialmente creado para ser sencillo de usar
                por cualquier usuario que no esté familiarizado con las nuevas
                tecnologías.
              </p>
            </li>
            <li class="features-block-two">
              <img
                src="images/Grupo-3906.svg"
                loading="lazy"
                alt=""
                class="features-image"
              />
              <p style="text-align: justify">
                <strong class="bold-text-5">Comunicación inmediata </strong
                ><br />En cualquier momento podremos comunicarnos mediante una
                simple videoconferencia.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section class="pricing-items wf-section" id="planes">
      <h1 class="heading-11">
        Tenemos una serie de soluciones para cuidar a tus seres queridos
      </h1>
      <div class="containerhtml">
        <div class="pricing-wrapper-two">
          <div class="pricing-card-two">
            <div class="div-block-34">
              <img
                src="images/Kit-1.png"
                loading="lazy"
                style="opacity: 0"
                data-w-id="7032ef33-3d9e-0ccd-28db-ce590babf42b"
                sizes="(max-width: 479px) 92vw, (max-width: 767px) 81vw, (max-width: 991px) 24vw, 32vw"
                srcset="images/Kit-1-p-500.png 500w, images/Kit-1.png 637w"
                alt=""
                class="pricing-image-two shadow-two"
              />
              <a href="#" class="lightbox-link-2 _2 w-inline-block w-lightbox">
                <div class="text-block-4">Ver Video</div>
                <script type="application/json" class="w-json">
                  {
                    "items": [
                      {
                        "url": "https://www.youtube.com/watch?v=qbJTwBc3Ook",
                        "originalUrl": "https://www.youtube.com/watch?v=qbJTwBc3Ook",
                        "width": 940,
                        "height": 528,
                        "thumbnailUrl": "https://i.ytimg.com/vi/qbJTwBc3Ook/hqdefault.jpg",
                        "html": "<iframe class=\"embedly-embed\" src=\"//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FqbJTwBc3Ook%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DqbJTwBc3Ook&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FqbJTwBc3Ook%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube\" width=\"940\" height=\"528\" scrolling=\"no\" title=\"YouTube embed\" frameborder=\"0\" allow=\"autoplay; fullscreen\" allowfullscreen=\"true\"></iframe>",
                        "type": "video"
                      }
                    ],
                    "group": ""
                  }
                </script>
              </a>
            </div>
            <div class="pricing-content">
              <div class="pricing-info">
                <h3 class="heading">
                  <span
                    data-w-id="7a85dc64-c2df-b1ba-3610-68ff0a72c2cf"
                    style="opacity: 0"
                    class="text-span"
                    >Solución Personal </span
                  ><br />Cámara + App
                </h3>
                <!--a href="#" class="button-primary-3 outline-button contacto w-button">Contactar vendedor</a-->
                <a
                  href="/compras?plan=5"
                  class="button-primary outline-button w-button"
                  >Comprar</a
                >
              </div>
              <p
                data-w-id="7032ef33-3d9e-0ccd-28db-ce590babf437"
                style="text-align: justify; opacity: 0"
                class="pricing-details-text"
              >
                Al generar una alerta, tu red de seguridad podrá comunicarse
                contigo por videollamada. Puedes crear una red de seguridad de
                hasta 5 personas y visualizar la cámara desde tu App en tiempo
                real.
              </p>
              <p
                data-w-id="b949107c-ccbf-8079-756e-62dbe1fc4085"
                style="text-align: justify; opacity: 0"
                class="pricing-details-text red"
              >
                Una solución orientada a personas que necesiten todas las
                funcionalidades de SOSCLICK Con auto-monitoreo de una cámara
                provista por SOSCLICK.
              </p>
              <div class="pricing-divider-two"></div>
              <div class="tagline">
                <strong
                  data-w-id="d08d8275-94b0-82b2-32ee-b18df8abf7c7"
                  style="opacity: 0"
                  class="bold-text"
                  >Funcionalidades</strong
                >
              </div>
              <div class="pricing-details">
                <div class="pricing-feature">App SOSCLICK</div>
                <div
                  data-w-id="fadbc056-7363-e09e-19f8-363ba0e99440"
                  style="opacity: 0"
                  class="text-block-2"
                >
                  Acceso a la aplicación SOSCLICK que te permitirá manejar y
                  monitorear todo desde tu smartphone.
                </div>
              </div>
              <div class="pricing-details">
                <div
                  data-w-id="cb83d02b-242c-18e9-3907-baa01813c850"
                  style="opacity: 0"
                  class="pricing-feature"
                >
                  Red de 5 Usuarios
                </div>
                <div
                  data-w-id="cb83d02b-242c-18e9-3907-baa01813c852"
                  style="opacity: 0"
                  class="text-block-2"
                >
                  Al generar una alerta, tu red de seguridad podrá comunicarse
                  contigo por videollamada.
                </div>
              </div>
              <div class="pricing-details">
                <div
                  data-w-id="da52050a-e8d1-2c6e-0abc-fedc2e862c28"
                  style="opacity: 0"
                  class="pricing-feature"
                >
                  Cámara IP
                </div>
                <div
                  data-w-id="da52050a-e8d1-2c6e-0abc-fedc2e862c2a"
                  style="opacity: 0"
                  class="text-block-2"
                >
                  Podrás visualizar en tiempo real la cámara desde la aplicación
                  SOSCLICK.
                </div>
              </div>
            </div>
          </div>
          <div class="pricing-card-two">
            <div class="div-block-35">
              <img
                src="images/Kit-2.png"
                loading="lazy"
                style="opacity: 0"
                data-w-id="34b1dda8-1fd4-a1cc-dc1a-299aa1fc500a"
                sizes="(max-width: 479px) 92vw, (max-width: 767px) 81vw, (max-width: 991px) 24vw, 32vw"
                srcset="images/Kit-2-p-500.png 500w, images/Kit-2.png 637w"
                alt=""
                class="pricing-image-two shadow-two"
              />
              <a href="#" class="lightbox-link-2 _2 w-inline-block w-lightbox">
                <div class="text-block-4">Ver Video</div>
                <script type="application/json" class="w-json">
                  {
                    "items": [
                      {
                        "url": "https://www.youtube.com/watch?v=AHxx9Gds3Qk",
                        "originalUrl": "https://www.youtube.com/watch?v=AHxx9Gds3Qk",
                        "width": 940,
                        "height": 528,
                        "thumbnailUrl": "https://i.ytimg.com/vi/AHxx9Gds3Qk/hqdefault.jpg",
                        "html": "<iframe class=\"embedly-embed\" src=\"//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FAHxx9Gds3Qk%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DAHxx9Gds3Qk&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FAHxx9Gds3Qk%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube\" width=\"940\" height=\"528\" scrolling=\"no\" title=\"YouTube embed\" frameborder=\"0\" allow=\"autoplay; fullscreen\" allowfullscreen=\"true\"></iframe>",
                        "type": "video"
                      }
                    ],
                    "group": ""
                  }
                </script>
              </a>
            </div>
            <div class="pricing-content">
              <div class="pricing-info">
                <h3 class="heading">
                  <span class="text-span-2"
                    ><strong
                      data-w-id="9ffd19d5-435a-a563-8c7e-6aa946dfc2d7"
                      style="opacity: 0"
                      class="bold-text-3"
                      >Solución Full</strong
                    ></span
                  >
                  <br />Cámara + Parlantes + App
                </h3>
                <!--a href="#" class="button-primary-3 outline-button contacto w-button">Contactar vendedor</a-->
                <a
                  href="/compras?plan=6"
                  class="button-primary outline-button w-button"
                  >Comprar</a
                >
              </div>
              <p
                data-w-id="34b1dda8-1fd4-a1cc-dc1a-299aa1fc5011"
                style="text-align: justify; opacity: 0"
                class="pricing-details-text"
              >
                Al generar una alerta, tu red de seguridad podrá comunicarse
                contigo por videollamada. <br />Puedes crear una red de
                seguridad de hasta 5 personas y además visualizar la cámara
                desde tu App en tiempo real. <br /><br />Además, puedes tener
                una comunicación por voz vía la cámara, escuchando con claridad
                a tu interlocutor, ya que, los parlantes externos aseguran una
                excelente comunicación bidireccional.<br />
              </p>
              <p
                data-w-id="3163ae88-fcd4-d25f-482e-84ab20de0a76"
                style="text-align: justify; opacity: 0"
                class="pricing-details-text red"
              >
                Plan orientado a personas que necesiten todas las
                funcionalidades de SOSCLICK con monitoreo de una cámara, que
                incluye parlantes adicionales.
              </p>
              <div class="pricing-divider-two"></div>
              <div class="tagline">
                <strong
                  data-w-id="34b1dda8-1fd4-a1cc-dc1a-299aa1fc5015"
                  style="opacity: 0"
                  class="bold-text"
                  >Funcionalidades</strong
                >
              </div>
              <div class="pricing-details">
                <div
                  data-w-id="6a386b78-19f7-1800-a0c4-a35c134b3464"
                  style="opacity: 0"
                  class="pricing-feature"
                >
                  App SOSCLICK
                </div>
                <div class="text-block-2">
                  Acceso a la aplicación SOSCLICK que te permitirá manejar y
                  monitorear todo desde tu smartphone.
                </div>
              </div>
              <div class="pricing-details">
                <div class="pricing-feature">Red de 5 Usuarios</div>
                <div class="text-block-2">
                  Al generar una alerta, tu red de seguridad podrá comunicarse
                  contigo por videollamada.
                </div>
              </div>
              <div class="pricing-details">
                <div class="pricing-feature">
                  Cámara IP + Parlantes Externos
                </div>
                <div class="text-block-2">
                  Podrás visualizar en tiempo real la cámara desde la aplicación
                  SOSCLICK.
                </div>
              </div>
            </div>
          </div>
          <div class="pricing-card-two">
            <div class="div-block-36">
              <img
                src="images/Kit-3.png"
                loading="lazy"
                style="opacity: 0"
                data-w-id="b47fe076-323b-0942-ff41-3b1a57810e3d"
                sizes="(max-width: 479px) 92vw, (max-width: 767px) 81vw, (max-width: 991px) 23vw, 32vw"
                srcset="images/Kit-3-p-500.png 500w, images/Kit-3.png 637w"
                alt=""
                class="pricing-image-two shadow-two"
              />
              <a href="#" class="lightbox-link-2 _2 w-inline-block w-lightbox">
                <div class="text-block-4">Ver Video</div>
                <script type="application/json" class="w-json">
                  {
                    "items": [
                      {
                        "url": "https://www.youtube.com/watch?v=MsvMRaDBlB4",
                        "originalUrl": "https://www.youtube.com/watch?v=MsvMRaDBlB4",
                        "width": 940,
                        "height": 528,
                        "thumbnailUrl": "https://i.ytimg.com/vi/MsvMRaDBlB4/hqdefault.jpg",
                        "html": "<iframe class=\"embedly-embed\" src=\"//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FMsvMRaDBlB4%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DMsvMRaDBlB4&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FMsvMRaDBlB4%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube\" width=\"940\" height=\"528\" scrolling=\"no\" title=\"YouTube embed\" frameborder=\"0\" allow=\"autoplay; fullscreen\" allowfullscreen=\"true\"></iframe>",
                        "type": "video"
                      }
                    ],
                    "group": ""
                  }
                </script>
              </a>
            </div>
            <div class="pricing-content">
              <div class="pricing-info">
                <h3 class="heading">
                  <span class="text-span-3">Solución Premium</span> <br />Cámara
                  + Parlantes <br />Módem WIFI 4g + App
                </h3>
                <!--a href="#" class="button-primary-3 outline-button contacto w-button">Contactar vendedor</a-->
                <a
                  href="/compras?plan=7"
                  class="button-primary outline-button w-button"
                  >Comprar</a
                >
              </div>
              <p class="pricing-details-text" style="text-align: justify">
                Al generar una alerta, tu red de seguridad podrá comunicarse
                contigo por videollamada. Puedes crear una red de seguridad de
                hasta 5 personas y visualizar la cámara desde tu App en tiempo
                real.<br /><br />Además puedes tener una comunicación por voz
                vía la cámara, escuchando con claridad a tu interlocutor, ya
                que, los parlantes externos aseguran una excelente comunicación
                bidireccional. Podrás utilizar el Modem 4g en lugares que no
                cuenten con Internet. <br />
              </p>
              <p class="pricing-details-text red" style="text-align: justify">
                Plan orientado a personas que necesiten todas las
                funcionalidades de SOSCLICK con monitoreo de una cámara, que
                incluye parlantes adicionales, más un modem 4g para lugares que
                no cuenten con internet.
              </p>
              <div class="pricing-divider-two"></div>
              <div class="tagline">
                <strong class="bold-text">Funcionalidades</strong>
              </div>
              <div class="pricing-details">
                <div class="pricing-feature">App SOSCLICK</div>
                <div class="text-block-2">
                  Acceso a la aplicación SOSclick que te permitirá manejar y
                  monitorear todo desde tu smartphone
                </div>
              </div>
              <div class="pricing-details">
                <div class="pricing-feature">Red de 5 Usuarios</div>
                <div class="text-block-2">
                  Al generar una alerta, tu red de seguridad podrá comunicarse
                  contigo por videollamada.
                </div>
              </div>
              <div class="pricing-details">
                <div class="pricing-feature">
                  Cámara IP + Parlantes Externos
                </div>
                <div class="text-block-2">
                  Podrás visualizar en tiempo real la cámara desde la aplicación
                  SOSclick.
                </div>
              </div>
              <div class="pricing-details">
                <div class="pricing-feature">Módem WIFI 4g</div>
                <div class="text-block-2">
                  Podrás utilizar el Modem 4g para lugares que no cuentan con
                  internet.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="logos-title-large wf-section">
      <div class="containerhtml-3">
        <h2 class="clients-title">Otros Productos de la empresa</h2>
        <div
          data-w-id="aaf95f6e-92dc-5615-f4d2-fadc858fe285"
          style="opacity: 0"
          class="clients-wrapper"
        >
          <img
            src="images/logo-sosclick.png"
            loading="lazy"
            width="77"
            alt="The Paak logo"
            class="clients-image"
          /><img
            src="images/Grupo-43.png"
            loading="lazy"
            width="97"
            alt="Ephicient logo"
            class="clients-image"
          /><img
            src="images/Grupo-42.png"
            loading="lazy"
            width="153"
            alt="Arise Health logo"
            class="clients-image"
          /><img
            src="images/logo-login.svg"
            loading="lazy"
            width="139"
            alt="OE logo"
            class="clients-image"
          /><img
            src="images/logo-comuna.png"
            loading="lazy"
            width="165"
            alt="2020INC logo"
            class="clients-image"
          /><img
            src="images/Alo-Doctores.png"
            loading="lazy"
            width="185"
            alt="2020INC logo"
            class="clients-image"
          />
        </div>
      </div>
    </section>
    <section class="testimonial-slider-large wf-section">
      <div class="containerhtml-3">
        <div
          data-delay="4000"
          data-animation="slide"
          class="testimonial-slider-two w-slider"
          data-autoplay="false"
          data-easing="ease"
          data-hide-arrows="false"
          data-disable-swipe="false"
          data-autoplay-limit="0"
          data-nav-spacing="12"
          data-duration="500"
          data-infinite="true"
        >
          <div class="mask w-slider-mask">
            <div class="w-slide">
              <div class="testimonial-slide">
                <div class="testimonial-content">
                  <img
                    src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a91e612aae8_quote-mark.svg"
                    loading="lazy"
                    alt=""
                    class="testimonial-quote-icon"
                  />
                  <div class="testimonial-quote">
                    Cuidar a mis padres para mí siempre es una prioridad y
                    SOSclick me ayuda a lograrlo
                  </div>
                  <div class="testimonial-info-two">
                    <img
                      src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a28a812aad9_placeholder%202.svg"
                      loading="lazy"
                      alt=""
                      class="testimonial-image"
                    />
                    <div>
                      <div class="testimonial-author-name">Diego Lagos</div>
                      <div>Hijo</div>
                    </div>
                  </div>
                </div>
                <img
                  src="images/senior-man-with-daughter-outdoors-2022-03-08-01-34-21-utc-min.jpeg"
                  loading="lazy"
                  srcset="
                    images/senior-man-with-daughter-outdoors-2022-03-08-01-34-21-utc-min-p-500.jpeg   500w,
                    images/senior-man-with-daughter-outdoors-2022-03-08-01-34-21-utc-min-p-800.jpeg   800w,
                    images/senior-man-with-daughter-outdoors-2022-03-08-01-34-21-utc-min-p-1080.jpeg 1080w,
                    images/senior-man-with-daughter-outdoors-2022-03-08-01-34-21-utc-min-p-1600.jpeg 1600w,
                    images/senior-man-with-daughter-outdoors-2022-03-08-01-34-21-utc-min-p-2000.jpeg 2000w,
                    images/senior-man-with-daughter-outdoors-2022-03-08-01-34-21-utc-min-p-2600.jpeg 2600w,
                    images/senior-man-with-daughter-outdoors-2022-03-08-01-34-21-utc-min-p-3200.jpeg 3200w,
                    images/senior-man-with-daughter-outdoors-2022-03-08-01-34-21-utc-min.jpeg        7360w
                  "
                  sizes="(max-width: 479px) 84vw, (max-width: 767px) 89vw, (max-width: 991px) 21vw, 350px"
                  alt=""
                  class="testimonial-image-two"
                />
              </div>
            </div>
            <div class="w-slide">
              <div class="testimonial-slide">
                <div class="testimonial-content">
                  <img
                    src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a91e612aae8_quote-mark.svg"
                    loading="lazy"
                    alt=""
                    class="testimonial-quote-icon"
                  />
                  <div class="testimonial-quote">
                    Desde que abuelo falleció siempre me preocupo de que mi
                    abuela se sintiera muy sola, pero con SOSclick ahora puedo
                    estar con ella siempre desde cualquier parte
                  </div>
                  <div class="testimonial-info-two">
                    <img
                      src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a28a812aad9_placeholder%202.svg"
                      loading="lazy"
                      alt=""
                      class="testimonial-author-image-three"
                    />
                    <div>
                      <div class="testimonial-author-name">
                        Maria Victoria Rodriguez
                      </div>
                      <div>Nieta</div>
                    </div>
                  </div>
                </div>
                <img
                  src="images/happy-senior-woman-with-spectacles-laughing-at-hom-2022-02-01-22-38-30-utc-min.jpg"
                  loading="lazy"
                  srcset="
                    images/happy-senior-woman-with-spectacles-laughing-at-hom-2022-02-01-22-38-30-utc-min-p-500.jpg   500w,
                    images/happy-senior-woman-with-spectacles-laughing-at-hom-2022-02-01-22-38-30-utc-min-p-800.jpg   800w,
                    images/happy-senior-woman-with-spectacles-laughing-at-hom-2022-02-01-22-38-30-utc-min-p-1080.jpg 1080w,
                    images/happy-senior-woman-with-spectacles-laughing-at-hom-2022-02-01-22-38-30-utc-min-p-1600.jpg 1600w,
                    images/happy-senior-woman-with-spectacles-laughing-at-hom-2022-02-01-22-38-30-utc-min-p-2000.jpg 2000w,
                    images/happy-senior-woman-with-spectacles-laughing-at-hom-2022-02-01-22-38-30-utc-min-p-2600.jpg 2600w,
                    images/happy-senior-woman-with-spectacles-laughing-at-hom-2022-02-01-22-38-30-utc-min-p-3200.jpg 3200w,
                    images/happy-senior-woman-with-spectacles-laughing-at-hom-2022-02-01-22-38-30-utc-min.jpg        6192w
                  "
                  sizes="(max-width: 479px) 84vw, (max-width: 767px) 89vw, (max-width: 991px) 21vw, 350px"
                  alt=""
                  class="testimonial-image-two"
                />
              </div>
            </div>
            <div class="w-slide">
              <div class="testimonial-slide">
                <div class="testimonial-content">
                  <img
                    src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a91e612aae8_quote-mark.svg"
                    loading="lazy"
                    alt=""
                    class="testimonial-quote-icon"
                  />
                  <div class="testimonial-quote">
                    Mi padre vive en el Sur solo desde que me vine a Santiago y
                    siempre me preocupo de que le pasara algo y no lo pudiera
                    asistir
                  </div>
                  <div class="testimonial-info-two">
                    <img
                      src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a28a812aad9_placeholder%202.svg"
                      loading="lazy"
                      alt=""
                      class="testimonial-author-image-three"
                    />
                    <div>
                      <div class="testimonial-author-name">
                        Marcos Fuenzalida
                      </div>
                      <div>Hijo</div>
                    </div>
                  </div>
                </div>
                <img
                  src="images/senior-man-with-disability-sitting-in-the-room-2022-02-03-00-22-07-utc-min.jpg"
                  loading="lazy"
                  srcset="
                    images/senior-man-with-disability-sitting-in-the-room-2022-02-03-00-22-07-utc-min-p-500.jpg   500w,
                    images/senior-man-with-disability-sitting-in-the-room-2022-02-03-00-22-07-utc-min-p-800.jpg   800w,
                    images/senior-man-with-disability-sitting-in-the-room-2022-02-03-00-22-07-utc-min-p-1080.jpg 1080w,
                    images/senior-man-with-disability-sitting-in-the-room-2022-02-03-00-22-07-utc-min-p-1600.jpg 1600w,
                    images/senior-man-with-disability-sitting-in-the-room-2022-02-03-00-22-07-utc-min-p-2000.jpg 2000w,
                    images/senior-man-with-disability-sitting-in-the-room-2022-02-03-00-22-07-utc-min-p-2600.jpg 2600w,
                    images/senior-man-with-disability-sitting-in-the-room-2022-02-03-00-22-07-utc-min-p-3200.jpg 3200w,
                    images/senior-man-with-disability-sitting-in-the-room-2022-02-03-00-22-07-utc-min.jpg        8256w
                  "
                  sizes="(max-width: 479px) 84vw, (max-width: 767px) 89vw, (max-width: 991px) 21vw, 350px"
                  alt=""
                  class="testimonial-image-two"
                />
              </div>
            </div>
          </div>
          <div class="testimonial-slider-arrow w-slider-arrow-left">
            <div class="w-icon-slider-left"></div>
          </div>
          <div class="testimonial-slider-arrow w-slider-arrow-right">
            <div class="w-icon-slider-right"></div>
          </div>
          <div
            class="
              testimonial-slider-nav
              w-slider-nav w-slider-nav-invert w-round
            "
          ></div>
        </div>
      </div>
    </section>
    <div id="servicios" class="servicios wf-section">
      <h1 class="heading-15">APP SOSCLICK</h1>
      <div class="linea gris"></div>
      <div class="div-block-30">
        <p class="paragraph-6 _3" style="text-align: left">
          Al ser usuario de SOSCLICK tendrás acceso a tu perfil<strong
          ></strong>, en el cual podrás configurar y activar todas las
          funcionalidades de SOSCLICK
        </p>
      </div>
      <div class="div-block-25-copy">
        <h1 class="heading-5-copy">
          <strong class="bold-text-8">CREA TU RED DE SEGURIDAD</strong>
        </h1>
        <p class="paragraph-6 _3" style="text-align: justify !important">
          Agrega a tus familiares, amigos y/o vecinos a tu red de seguridad
          SOSCLICK. Puedes hacerlo enviando el link de registro con tu código
          desde la aplicación, todos los usuarios que se registren con ese
          código quedarán asociados a tu red de seguridad. O también puedes
          hacerlo enviando una solicitud a un usuario registrado en SOSCLICK.
          Sólo debe invitarlo via whatsapp, correo electrónico, etc, así el
          sistema le pedirá a ese usuario la confirmación antes de ingresar a tu
          red de seguridad.
        </p>
      </div>
      <div class="div-block-18-copy">
        <div class="div-block-26">
          <img
            src="images/Botton-Red-seguridad.png"
            loading="lazy"
            srcset="
              images/Botton-Red-seguridad-p-500.png 500w,
              images/Botton-Red-seguridad.png       502w
            "
            sizes="(max-width: 479px) 120px, 100vw"
            alt=""
            class="image-13"
          />
          <h1 class="heading-17">
            <strong class="bold-text-12">RED DE SEGURIDAD</strong>
          </h1>
          <p class="paragraph-7 _2" style="text-align: justify">
            La Red de Seguridad le permite a todo usuario invitar a familiares,
            amigos o vecinos a ser parte de ésta, con ello estamos contribuyendo
            a una Comunidad altamente comunicada y con mayor acción de disuasión
            ante la delincuencia.<br />
          </p>
        </div>
        <div class="div-block-24">
          <img
            src="images/Red-seguridad.png"
            loading="lazy"
            width="678"
            srcset="
              images/Red-seguridad-p-500.png   500w,
              images/Red-seguridad-p-800.png   800w,
              images/Red-seguridad-p-1080.png 1080w,
              images/Red-seguridad.png        1436w
            "
            sizes="(max-width: 991px) 100vw, 51vw"
            alt=""
            class="image-12"
          />
        </div>
      </div>
      <div class="div-block-27">
        <div class="columns-2 w-row">
          <div class="column-2 w-col w-col-6">
            <img
              src="https://uploads-ssl.webflow.com/626c7f415a3abfb5c463b91a/627305480885006182961589_Generar-alerta.png"
              loading="lazy"
              width="544"
              alt=""
              class="image-14"
            />
          </div>
          <div class="column-3 w-col w-col-6">
            <h1 class="heading-16">
              <strong class="bold-text-9">AL GENERAR <br />UNA ALERTA</strong>
            </h1>
            <p class="paragraph-5" style="text-align: justify">
              SOSCLICK enviará notificaciones a todas las personas que
              pertenezcan a tu red de seguridad. Esta notificación se envía con
              la ubicación desde donde se generó la alerta. Cualquier usuario de
              tu red SOSCLICK puede establecer una videollamada con el usuario
              que generó la Alerta o Contactarlo por una llamada telefónica
              obteniendo de manera inmediata una comunicación con este usuario.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="section-2 wf-section">
      <div class="div-block-23">
        <h1 class="heading-13">Preguntas Frecuentes</h1>
        <div
          data-current="Tab 6"
          data-easing="ease"
          data-duration-in="300"
          data-duration-out="100"
          class="tabs w-tabs"
        >
          <div class="tabs-menu w-tab-menu">
            <a
              data-w-tab="Tab 1"
              class="tab-link-tab-1 w-inline-block w-tab-link"
            >
              <div>Como Reiniciar mi camara</div>
            </a>
            <a data-w-tab="Tab 2" class="w-inline-block w-tab-link">
              <div>Tab 2</div>
            </a>
            <a data-w-tab="Tab 3" class="w-inline-block w-tab-link">
              <div>Tab 3</div>
            </a>
            <a data-w-tab="Tab 4" class="w-inline-block w-tab-link">
              <div>Tab 4</div>
            </a>
            <a data-w-tab="Tab 5" class="w-inline-block w-tab-link">
              <div>Tab 5</div>
            </a>
            <a data-w-tab="Tab 6" class="w-inline-block w-tab-link w--current">
              <div>Como Reiniciar mi camara</div>
            </a>
          </div>
          <div class="tabs-content w-tab-content">
            <div data-w-tab="Tab 1" class="tab-pane-tab-1 w-tab-pane"></div>
            <div data-w-tab="Tab 2" class="tab-pane-tab-2 w-tab-pane"></div>
            <div data-w-tab="Tab 3" class="tab-pane-tab-3 w-tab-pane"></div>
            <div data-w-tab="Tab 4" class="tab-pane-tab-4 w-tab-pane"></div>
            <div data-w-tab="Tab 5" class="tab-pane-tab-5 w-tab-pane"></div>
            <div
              data-w-tab="Tab 6"
              class="tab-pane-tab-6 w-tab-pane w--tab-active"
            >
              <div class="videos">
                <div class="div-block">
                  <a href="#" class="lightbox-link w-inline-block w-lightbox"
                    ><img
                      src="images/13.Como-reiniciar-mi-cámara-ip.png"
                      loading="lazy"
                      width="250"
                      height="120"
                      alt=""
                      class="image-10"
                    />
                    <script type="application/json" class="w-json">
                      {
                        "items": [
                          {
                            "url": "https://www.youtube.com/watch?v=UzPhT1HKB9g&list=PLqHAbgSZB6gabRIvlQ1VPczs_5y2l443q&index=13",
                            "originalUrl": "https://www.youtube.com/watch?v=UzPhT1HKB9g&list=PLqHAbgSZB6gabRIvlQ1VPczs_5y2l443q&index=13",
                            "width": 940,
                            "height": 528,
                            "thumbnailUrl": "https://i.ytimg.com/vi/UzPhT1HKB9g/hqdefault.jpg",
                            "html": "<iframe class=\"embedly-embed\" src=\"//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FUzPhT1HKB9g%3Flist%3DPLqHAbgSZB6gabRIvlQ1VPczs_5y2l443q&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DUzPhT1HKB9g&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FUzPhT1HKB9g%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube\" width=\"940\" height=\"528\" scrolling=\"no\" title=\"YouTube embed\" frameborder=\"0\" allow=\"autoplay; fullscreen\" allowfullscreen=\"true\"></iframe>",
                            "type": "video"
                          }
                        ],
                        "group": ""
                      }
                    </script>
                  </a>
                </div>
                <div class="text-video">Como reiniciar mi cámara ip</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="pricing-comparison wf-section">
      <div class="container">
        <div class="pricing-wrapper">
          <div
            id="w-node-_51240f6a-8a91-ad81-56c4-92e84563de82-f85b217c"
            class="pricing-card"
          >
            <img
              src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a28a812aad9_placeholder%202.svg"
              loading="lazy"
              alt=""
              class="pricing-image"
            />
            <h2 class="pricing-title">Startup</h2>
            <div class="pricing-subtitle">Starting at</div>
            <div class="pricing-price">$599/mo</div>
            <div class="paragraph-regular margin-bottom-20">
              Lorem ipsum dolor
            </div>
            <a href="#" class="button-primary outline-button w-button"
              >Talk to an Expert</a
            >
            <div class="pricing-divider"></div>
            <ul role="list" class="pricing-feature-list w-list-unstyled">
              <li>
                <div class="pricing-feature">Lorem ipsum dolor sit amet</div>
              </li>
              <li>
                <div class="pricing-feature">Lorem ipsum dolor sit amet</div>
              </li>
              <li>
                <div class="pricing-feature">Lorem ipsum dolor sit amet</div>
              </li>
              <li>
                <div class="pricing-feature">Lorem ipsum dolor sit amet</div>
              </li>
              <li>
                <div class="pricing-feature">Lorem ipsum dolor sit amet</div>
              </li>
              <li>
                <div class="pricing-feature">Lorem ipsum dolor sit amet</div>
              </li>
            </ul>
          </div>
          <div
            id="w-node-_51240f6a-8a91-ad81-56c4-92e84563dea2-f85b217c"
            class="pricing-card featured-pricing"
          >
            <img
              src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a867e12aade_portfolio%201%20-%20wide.svg"
              loading="lazy"
              alt=""
              class="pricing-image"
            />
            <h2 class="pricing-title">Growth</h2>
            <div class="pricing-subtitle">Starting at</div>
            <div class="pricing-price">$1,499/mo</div>
            <div class="paragraph-regular margin-bottom-20">
              Lorem ipsum dolor
            </div>
            <a href="#" class="button-primary w-button">Talk to an Expert</a>
            <div class="pricing-divider"></div>
            <ul role="list" class="pricing-feature-list w-list-unstyled">
              <li>
                <div class="pricing-feature">
                  Etiam sodales ac felis id interdum
                </div>
              </li>
              <li>
                <div class="pricing-feature">
                  Etiam sodales ac felis id interdum
                </div>
              </li>
              <li>
                <div class="pricing-feature">
                  Etiam sodales ac felis id interdum
                </div>
              </li>
              <li>
                <div class="pricing-feature">
                  Etiam sodales ac felis id interdum
                </div>
              </li>
              <li>
                <div class="pricing-feature">
                  Etiam sodales ac felis id interdum
                </div>
              </li>
              <li>
                <div class="pricing-feature">
                  Etiam sodales ac felis id interdum
                </div>
              </li>
              <li>
                <div class="pricing-feature">
                  Etiam sodales ac felis id interdum
                </div>
              </li>
              <li>
                <div class="pricing-feature">
                  Etiam sodales ac felis id interdum
                </div>
              </li>
            </ul>
            <div class="pricing-tag">Recommended</div>
          </div>
          <div
            id="w-node-_51240f6a-8a91-ad81-56c4-92e84563deca-f85b217c"
            class="pricing-card"
          >
            <img
              src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a55c612aae2_portfolio%202%20-%20wide.svg"
              loading="lazy"
              alt=""
              class="pricing-image"
            />
            <h2 class="pricing-title">Enterprise</h2>
            <div class="pricing-subtitle">Starting at</div>
            <div class="pricing-price">$2,499/mo</div>
            <div class="paragraph-regular margin-bottom-20">
              Lorem ipsum dolor
            </div>
            <a href="#" class="button-primary outline-button w-button"
              >Talk to an Expert</a
            >
            <div class="pricing-divider"></div>
            <ul role="list" class="pricing-feature-list w-list-unstyled">
              <li>
                <div class="pricing-feature">Lorem ipsum dolor sit amet</div>
              </li>
              <li>
                <div class="pricing-feature">Lorem ipsum dolor sit amet</div>
              </li>
              <li>
                <div class="pricing-feature">Lorem ipsum dolor sit amet</div>
              </li>
              <li>
                <div class="pricing-feature">Lorem ipsum dolor sit amet</div>
              </li>
              <li>
                <div class="pricing-feature">Lorem ipsum dolor sit amet</div>
              </li>
              <li>
                <div class="pricing-feature">Lorem ipsum dolor sit amet</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  getPaymentValue,
  startPaymentPlan,
  getOrderStatusByToken,
} from "../helpers/api/planes";
export default {
  name: "Home",
  components: {},
  data: () => ({
    toPayPlan: {
      plan_id: 1,
      months_paid: 6,
    },
    loading: false,
    requestedStatus: false,
    playing: false,
  }),
    mounted() {
    if (!this.requestedStatus && this.$route.query && this.$route.query.token) {
      this.getOrderStatus(this.$route.query.token);
    }
  },
 methods: {
    async startPayment() {
      const planPayment = await startPaymentPlan(this.toPayPlan);

      if (planPayment.code === 200) {
        if (
          planPayment.flowData &&
          planPayment.flowData.url &&
          planPayment.flowData.token
        ) {
          const payUrl =
            planPayment.flowData.url + "?token=" + planPayment.flowData.token;
          window.location.href = payUrl;
        }
      }
    },
    async getOrderStatus(order_token) {
      this.requestedStatus = true;

      this.loading = true;
      const status = await getOrderStatusByToken({ order_token });
      if (status.code === 200 && status.orderStatus) {
        console.log(status.orderStatus);
        const data = {
          flowOrder: status.orderStatus.flowOrder,
          amount: status.orderStatus.amount,
          status: status.orderStatus.status,
          forma: status.orderStatus.paymentData
            ? status.orderStatus.paymentData.media
            : "",
          fecha: status.orderStatus.paymentData
            ? status.orderStatus.paymentData.date
            : "",
        };
        this.$store.commit("setModalsStates", {
          paymentStatus: { open: true, data },
        });
      }
    },
  },
};
</script>

<style>
@import "../../assets/css/normalize.css";
@import "../../assets/css/webflow.css";
@import "../../assets/css/sosclick-abuelos.webflow.css";

[data-wf-bgvideo-fallback-img] {
  display: none;
}

@media (prefers-reduced-motion: reduce) {
  [data-wf-bgvideo-fallback-img] {
    position: absolute;
    z-index: -100;
    display: inline-block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>