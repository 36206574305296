<template>
      <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar-2 w-nav">
    <div class="containerhtml-2 w-containerhtml">
      <nav role="navigation" class="nav-menu w-nav-menu">
        <a href="https://www.sosclick.cl/"  class="nav-link2-3 w-nav-link" target="_self">Web SOSClick</a>
        <a href="/#planes" class="nav-link2-3 w-nav-link">Planes</a>
        <a href="https://ventasboton.sosclick.cl/" class="nav-link2-3 w-nav-link" target="_self">Botón Adulto Mayor</a>
        <a href="http://ventas.iduam.com/" class="nav-link2-3 w-nav-link" target="_self">Iduam</a>
        <a href="https://www.youtube.com/channel/UC9_7svBs8MccyNykc8L3qRg" class="nav-link2-3 w-nav-link" target="_self">VideoTutoriales</a>        
        <a href="#" class="nav-link2-3 w-nav-link">Contacto</a>
      </nav>
      <a href="#" class="brand w-nav-brand"><img src="images/Logo.svg" loading="lazy" width="96" alt="" class="image-6"></a>
      <div class="menu-button-2 w-nav-button">
        <div class="icon w-icon-nav-menu"></div>
      </div>
      <div class="div-block-16"></div>
      <div class="div-block-17"><img src="images/iconos-pantallas.svg" loading="lazy" width="208" alt="" class="image-7"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {};
  },
};

</script>
<style>
  

</style>